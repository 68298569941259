exports.components = {
  "component---src-pages-grafik-js": () => import("./../../../src/pages/grafik.js" /* webpackChunkName: "component---src-pages-grafik-js" */),
  "component---src-pages-nasza-kadra-js": () => import("./../../../src/pages/nasza-kadra.js" /* webpackChunkName: "component---src-pages-nasza-kadra-js" */),
  "component---src-pages-nasze-pary-js": () => import("./../../../src/pages/nasze-pary.js" /* webpackChunkName: "component---src-pages-nasze-pary-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-index-js": () => import("./../../../src/pages/oferta/index.js" /* webpackChunkName: "component---src-pages-oferta-index-js" */),
  "component---src-pages-oferta-kursy-dla-doroslych-js": () => import("./../../../src/pages/oferta/kursy-dla-doroslych.js" /* webpackChunkName: "component---src-pages-oferta-kursy-dla-doroslych-js" */),
  "component---src-pages-oferta-pierwszy-taniec-js": () => import("./../../../src/pages/oferta/pierwszy-taniec.js" /* webpackChunkName: "component---src-pages-oferta-pierwszy-taniec-js" */),
  "component---src-pages-oferta-pokazy-js": () => import("./../../../src/pages/oferta/pokazy.js" /* webpackChunkName: "component---src-pages-oferta-pokazy-js" */),
  "component---src-pages-oferta-proam-js": () => import("./../../../src/pages/oferta/proam.js" /* webpackChunkName: "component---src-pages-oferta-proam-js" */),
  "component---src-pages-oferta-zajecia-dla-dzieci-js": () => import("./../../../src/pages/oferta/zajecia-dla-dzieci.js" /* webpackChunkName: "component---src-pages-oferta-zajecia-dla-dzieci-js" */),
  "component---src-pages-oferta-zajecia-w-szkolach-js": () => import("./../../../src/pages/oferta/zajecia-w-szkolach.js" /* webpackChunkName: "component---src-pages-oferta-zajecia-w-szkolach-js" */),
  "component---src-pages-studio-tanca-js": () => import("./../../../src/pages/studio-tanca.js" /* webpackChunkName: "component---src-pages-studio-tanca-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blogPost.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-index-js": () => import("./../../../src/templates/index.js" /* webpackChunkName: "component---src-templates-index-js" */)
}

